<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Composition cellule client" />
          </div>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Ajouter une composition de cellule client</div>
              </v-row>
            </v-card-title>

            <!-- Le produit -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">produit</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    v-model="selectedProduct"
                    :items="products"
                    item-text="digitalName"
                    return-object
                    placeholder="Associer un produit"
                    class="ma-0 pa-0"
                    no-data-text="aucun produit"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- Les périmètres -->
            <v-card-text>
              <v-row no-gutters align="start">
                <v-col cols="5" justify="center" align-self="start">
                  <div class="capitalize font-weight-regular">
                    Périmètre(s)
                  </div>
                </v-col>
                <v-col cols="7" align-self="start">
                  <div
                    v-for="(perimeter, i) in associatePerimeter"
                    :key="i"
                    v-show="associatePerimeter.length > 0"
                  >
                    <v-row
                      no-gutters
                      align="start"
                      justify="space-between"
                      class="mb-1"
                    >
                      <v-col cols="7">
                        <div>{{ perimeter.label }}</div>
                      </v-col>
                      <v-col cols="4">
                        <div>{{ perimeter.value }}</div>
                      </v-col>
                      <v-col cols="1" align="end">
                        <v-btn
                          icon
                          color="primary"
                          @click="removeAssociatePerimeter(perimeter)"
                        >
                          <v-icon>mdi-window-close</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <!-- Affichage de la liste des périmètres associables -->
                  <div>
                    <v-row no-gutters justify="space-between" align="start">
                      <v-col cols="7" class="px-1">
                        <v-autocomplete
                          v-model="selectedPerimeter"
                          :items="availablePerimeter"
                          item-text="label"
                          return-object
                          placeholder="Associer un périmètre"
                          hint="Associer un périmètre, inscrire sa valeur puis appuyer sur le bouton '+'"
                          clearable
                          class="ma-0 pa-0"
                          no-data-text="aucun périmètre"
                        >
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="4" class="px-1">
                        <v-text-field
                          placeholder="La valeur"
                          v-model="valueSelectedPerimeter"
                          class="ma-0 pa-0"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="1" align="end">
                        <v-btn
                          color="primary"
                          icon
                          @click="addPerimeter(selectedPerimeter)"
                          :disabled="!canAddPerimeter"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!canSave"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>
    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";

import { CustomerCellsService } from "@/service/conf/transmission/customer_cells_service.js";
import { AvecProductsService } from "@/service/conf/avec_products_service.js";
import { PerimetersService } from "@/service/conf/transmission/perimeters_service.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "AddCustomerCells",
  components: { Workflow, TitleAndReturnComponent },

  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin],
  data() {
    return {
      /** le service des entités */
      service: null,
      /** le service des activités */
      serviceProducts: null,
      /** le service des sous-activités */
      servicePerimeters: null,

      /**en cours de chargement */
      loading: false,

      /** la totalité des produits */
      products: [],
      /** le produit sélectionné */
      selectedProduct: null,

      /** les périmètres associés */
      associatePerimeter: [],
      /** les périmètres */
      perimeters: [],

      /** le périmètre sélectionné */
      selectedPerimeter: null,
      /** la valeur associé au périmètre sélectionné */
      valueSelectedPerimeter: "",

    };
  },
  methods: {

    // Chargement des datas
    async load() {
      try {
        this.loading = true;

        // Récupération des produits
        this.products = await this.serviceProducts.getAll();
        // Récupération des périmètres
        this.perimeters = await this.servicePerimeters.getAll();
        
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    /** Sauvegarde des datas */
    async save() {
      try {
        let item = {};
        item.avecProduct = this.selectedProduct.id;

        item.items = [];
        // Parcours des périmètres associés
        for (let peri of this.associatePerimeter) {
          let entity = {};
          entity.perimeter = peri.perimeter;
          entity.value = peri.value;

          item.items.push(entity);
        }

        // Sauvegarde de l'entité
        await this.service.create(item);

        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "ajouter : " + (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Retirer un périmètre des association */
    removeAssociatePerimeter(perimeter) {
      this.associatePerimeter.splice(
        this.associatePerimeter.findIndex((p) => p.id == perimeter.id),
        1
      );
    },

    /** ajout du périmètre et valeur */
    addPerimeter(perimeter) {
      if (!perimeter) return;

      // Association des datas pour fabrication d'un périmètre
      let perimeterAdd = {};
      perimeterAdd.perimeter = perimeter.id;
      perimeterAdd.label = perimeter.label;
      perimeterAdd.value = this.valueSelectedPerimeter;

      this.associatePerimeter.push(perimeterAdd);
      this.selectedPerimeter = null;
      this.valueSelectedPerimeter = "";
    }
  },
  computed: {
    /** les périmètres disponibles */
    availablePerimeter() {
      let available = [];
      let ids = this.associatePerimeter.map((a) => a.perimeter);
      available = this.perimeters.filter((p) => !ids.includes(p.id));

      return available;
    },

    /** activation du périmètre */
    canAddPerimeter() {
      if (!this.selectedPerimeter) {
        return false;
      }

      // if (this.valueSelectedPerimeter == undefined
      //   || this.valueSelectedPerimeter == null) {
      //     return false;
      // }

      return true;
    },

    completed() {
      if (!this.selectedProduct) return false;
      if (!this.associatePerimeter 
      || this.associatePerimeter.length == 0) return false;

      return true;
    },

    canSave() {
      return this.completed;
    },
  },
  mounted() {
    this.service = new CustomerCellsService(this.$api.getCustomerCellsApi());
    this.serviceProducts = new AvecProductsService(this.$api.getTransmissionsAvecProductsApi());
    this.servicePerimeters = new PerimetersService(this.$api.getPerimetersApi());

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>